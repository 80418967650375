import classes from "./Modal.module.css";

const Modal = (props) => {
  return (
    <div className={classes.modal} onClick={props.onClick}>
      {props.children}
    </div>
  );
};

export default Modal;
