import classes from "./Template.module.css";
import CitiesList from "../CitiesList/CitiesList";
import closeBtn from "../../assets/close-button.svg";
import Modal from "./Modal";

const Template = (props) => {
  return (
    <Modal onClick={props.onCloseClick} className="modalRegion">
      <div className={classes.container} onClick={(event) => event.stopPropagation()}>
        <div className={classes.region_search__wrapper}>
          <h1 className={classes.section__title}>
            Мы работаем
            <span style={{ color: "#e30014" }}> по всей России и за рубежом</span>
          </h1>
          <div className={classes.region_search__close} onClick={props.onCloseClick}>
            <img src={closeBtn} alt="" />
          </div>
          <CitiesList></CitiesList>
        </div>
      </div>
    </Modal>
  );
};

export default Template;
