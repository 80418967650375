import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// if (document.getElementById("root")) {
  const root = ReactDOM.createRoot(
    document.getElementById("root")
  );
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
// }

// if (document.getElementById("root__desktop")) {
//   const root__desktop = ReactDOM.createRoot(
//     document.getElementById("root__desktop")
//   );
//   root__desktop.render(
//     <React.StrictMode>
//       <App />
//     </React.StrictMode>
//   );
// }

// if (document.getElementById("modal__search")) {
//   const modal_search = ReactDOM.createRoot(
//     document.getElementById("modal__search")
//   );
//   modal_search.render(
//     <React.StrictMode>
//       <App />
//     </React.StrictMode>
//   );
// }

// if (document.getElementById("modal__search-desktop")) {
//   const modal_searchDesktop = ReactDOM.createRoot(
//     document.getElementById("modal__search-desktop")
//   );
//   modal_searchDesktop.render(
//     <React.StrictMode>
//       <App />
//     </React.StrictMode>
//   );
// }

// if (document.getElementById("modal__search-opened")) {
//   const modal_searchOpened = ReactDOM.createRoot(
//     document.getElementById("modal__search-opened")
//   );
//   modal_searchOpened.render(
//     <React.StrictMode>
//       <App />
//     </React.StrictMode>
//   );
// }

// if (document.getElementById("footer__search")) {
//   const footer_search = ReactDOM.createRoot(
//     document.getElementById("footer__search")
//   );
//   footer_search.render(
//     <React.StrictMode>
//       <App />
//     </React.StrictMode>
//   );
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
