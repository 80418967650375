import React, { useState } from "react";
import logo from "./logo.svg";
import "normalize.css";
import "./App.css";

import LocationButton from "./components/LocationButton/LocationButton";
import Template from "./components/Template/Template";

const App = () => {
  // const [isOpen, setIsOpen] = useState(false);

  const toggleTemplate = () => {
    // console.log("clicked");
    // setIsOpen(!isOpen);

    if (document.body.classList.contains("modal-open--has")) {
      document.body.classList.toggle("modal-open--has");
      document.body.classList.toggle("modal-open--region");
    } else {
      document.body.classList.toggle("modal-open");
      document.body.classList.toggle("modal-open--region");
    }
  };

  return (
    <div className="region-search">
      <Template onCloseClick={toggleTemplate}></Template>
    </div>
  );
};

export default App;
