import classes from "./LocationButton.module.css";
import locationPin from "../../assets/location-pin.svg";
import locationPinRed from "../../assets/location-pin-red.svg";
import locationPinBlack from "../../assets/location-pin-black.svg";

const citiesSelectElement = document.getElementById("plugin_siteregion");

let selectedCityName;

if (citiesSelectElement) {
  selectedCityName =
    citiesSelectElement.options[citiesSelectElement.selectedIndex].text;
} else {
  selectedCityName = "Королёв";
}

const LocationButton = (props) => {
  return (
    <div className={classes.button} onClick={props.onClick}>
      <img src={locationPin} className={classes.pin_image__white} alt="" />
      <img src={locationPinBlack} className={classes.pin_image__black} alt="" />
      <img src={locationPinRed} className={classes.pin_image__red} alt="" />
      <span>{selectedCityName}</span>
    </div>
  );
};

export default LocationButton;
